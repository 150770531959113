.not-found {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background: url(../../static/image/bg.png) no-repeat;
  background-size: 100% 100%;
  color: #333;
  align-items: center;
}
.not-found .button_btn {
  width: 50%;
  height: 40px;
  border-radius: 25px;
  background: url(../../static/image/btn_back.png) no-repeat;
  background-size: 100% 100%;
  text-align: center;
  line-height: 40px;
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  margin-top: 30px;
}
.not-found .banner {
  width: 300px;
  height: 230px;
  margin-top: 25%;
}
.not-found .banner img {
  width: 100%;
}
.not-found .titel {
  margin-top: 20px;
  width: 80%;
  font-size: 14px;
  line-height: 17px;
  color: #999;
}
.not-found .content {
  width: 80%;
  font-size: 12px;
  margin-top: 20px;
  height: 30px;
  text-decoration: underline;
  color: #f95d37;
}
